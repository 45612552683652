<style scoped></style>
<template>
  <div>
    <v-dialog v-model="show_dialog" scrollable width="450">
      <v-card class="pb-0">
        <v-card-title class="primary_2 white--text" primary-title>
          <p class="text-h6">Upload Bank Statement</p>
          <v-spacer></v-spacer>
          <v-icon large color="white" @click="show_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-3 pb-0">
          <v-form
            ref="upload_bank_details_form"
            v-model="is_valid"
            lazy-validation
          >
            <div>Bank Name</div>
            <!-- <v-text-field
              outlined
              dense
              :rules="$rules.REQUIRED_FIELD"
              v-model="bank_name"
              required
              placeholder="Enter Bank Name"
            ></v-text-field> -->
            <v-container class="px-0 pb-3" fluid>
              <v-combobox
                v-model="bank_name"
                :items="bank_list"
                :loading="bank_name_loading"
                :search-input.sync="bank_name_search"
                clearable
                dense
                required
                :rules="$rules.REQURIED_FILED"
                item-text="bank_name"
                item-value="bank_name"
                label="Select Bank Name"
                outlined
                placeholder="Select Bank Name"
                @focus="getBankList"
                @keyup="getBankList"
              >
                <!-- @change="getCustomerList" -->
                <template v-slot:append-item>
                  <div v-intersect="endIntersect"></div>
                </template>
              </v-combobox>
            </v-container>
            <div>Account Holder Name</div>
            <v-text-field
              outlined
              dense
              :rules="$rules.REQUIRED_FIELD"
              v-model="account_holder_name"
              required
              placeholder="Enter Account Holder Name"
            ></v-text-field>
            <div>Account Number</div>
            <v-text-field
              outlined
              dense
              :rules="$rules.REQUIRED_FIELD"
              v-model="account_number"
              required
              placeholder="Enter Account number"
            ></v-text-field>
            <div>IFSC code</div>
            <v-text-field
              outlined
              dense
              v-model="ifsc_code"
              required
              :rules="$rules.REQUIRED_FIELD"
              placeholder="Enter IFSC code"
            ></v-text-field>
            <div>Document</div>
            <v-file-input
              outlined
              dense
              accept=".pdf"
              v-model="file"
              prepend-icon="mdi-file-pdf-box"
              placeholder="Select"
            ></v-file-input>
            <div>Password (if any)</div>
            <v-text-field
              outlined
              dense
              v-model="password"
              placeholder="Enter password"
            ></v-text-field>
            <div>Document Status</div>
            <v-radio-group v-model="is_reupload" row>
              <v-radio label="Upload" :value="false"></v-radio>
              <v-radio label="Reupload" :value="true"></v-radio>
            </v-radio-group>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-2">
          <v-btn
            color="secondary_2"
            :loading="btn_loader"
            :disabled="btn_loader"
            class="font-weight-bold white--text"
            @click="uploadBankStatement()"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      is_valid: true,
      show_dialog: false,
      file: null,
      password: "",
      is_reupload: false,
      btn_loader: false,
      ifsc_code: "",
      account_number: "",
      bank_name: "",
      account_holder_name: "",
      bank_list: [],
      bank_name_loading: false,
      bank_name_search: "",
    };
  },
  methods: {
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.getBankList();
      }
    },
    async openBankStatementDialog(data) {
      this.account_holder_name = data.account_holder_name;
      this.account_number = data.account_number;
      this.ifsc_code = data.ifsc_code;
      this.bank_name = data.bank_name;
      this.show_dialog = true;
      await this.getBankList();
    },
    getBankList() {
      const self = this;
      self.bank_name_loading = true;
      let params = {
        search_query: this.bank_name_search,
      };
      // console.log(params);
      const successHandler = (response) => {
        // console.log("bank list", response);
        self.bank_list = response.data.bank_list;
      };
      const finallyHandler = () => {
        self.bank_name_loading = false;
      };

      self.request_GET(
        self,
        self.$urls.BANK_NAME_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    uploadBankStatement() {
      if (
        this.bank_name &&
        this.ifsc_code &&
        this.account_number &&
        this.account_holder_name
      ) {
        const self = this;
        self.btn_loader = true;

        let form = new FormData();
        form.append("customer_id", self.decrypt(self.$route.params.id));
        form.append("ifsc_code", self.ifsc_code);
        if (self.bank_name.bank_name) {
          form.append("bank_name", self.bank_name.bank_name);
        } else {
          form.append("bank_name", self.bank_name);
        }
        form.append("account_holder_name", self.account_holder_name);
        form.append("account_number", self.account_number);
        form.append("file", self.file);
        form.append("document_password", self.password);
        form.append("is_reupload", self.is_reupload);

        const successHandler = (response) => {
          if (response.status == 200) {
            this.$emit("onSuccess");
            this.$refs.upload_bank_details_form.reset();
            this.is_reupload = false;
            self.btn_loader = false;
            this.show_dialog = false;
          }
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.request_POST(
          self,
          self.$urls.UPLOAD_BANK_STATEMENT,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      } else {
        this.showSnakeBar(
          "error",
          "Please fill Account Details(Bank Name, Account Holder Name, Account number, IFSC code)"
        );
      }
    },
  },
};
</script>
